  /* date picker css */
  .DateContainers {
    margin-top: 6px;
    position: relative;
  }
  
  .dateInput {
    width: 100%;
    height: 'auto';
    display: block;
  }
  .toDate{
    padding-left:15px;
    position: relative;
  }
  .toDate::after {
      content: "-";
      position: absolute;
      top: 34px;
      left: 0;
      color: #9a9a9a;
  }

  .react-datepicker__input-container input::placeholder {
    color: #000 !important;
    opacity: 0.9;
  }
  
  .dateInput input {
    width: 100%;
    background: transparent;
    color: #000;
    font-size: 12px;
    height: 46px;
    display: block;
    border: 1px solid #9a9a9a;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 6px 35px 5px 10px !important;
    cursor: pointer
  }
  
  .icon {
    width: 24px;
    position: absolute;
    display: block;
    top: 11px;
    right: 6px;
    cursor: pointer;
  }
  
  .icon svg {
    margin-top: 3px;
  }
  
  textarea:focus,
  input:focus {
    outline: none;
  }
  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
  }
  
  